<template>
  <div class="toast">
    <div v-if="props.icon" class="icon-container" :class="`color-${props.iconColor}`">
      <DrIcon :icon="props.icon" />
    </div>
    <div class="text-container">
      <span class="title">{{ props.title }}</span>
      <span class="body">{{ props.text }}</span>
    </div>
    <div class="action-container" v-if="props.action || props.deletable">
      <button class="action" v-if="props.action" @click="emit('action')">{{ props.action }}</button>
      <button v-if="props.deletable" @click="emit('quit')">
        {{ translate({ defaultMessage: "close" }) }}
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useTranslate } from "@/src/composables/use-translate"
import DrIcon from "@/src/ui/dr-icon.vue"

const props = withDefaults(
  defineProps<{
    title: string
    text: string
    icon: string
    deletable?: boolean
    action?: string
    iconColor: string
  }>(),
  {
    deletable: false,
    action: ""
  }
)

const emit = defineEmits<{
  (event: "action"): void
  (event: "quit"): void
}>()

const { translate } = useTranslate()
</script>

<style scoped lang="scss">
.toast {
  pointer-events: all;
  background-color: $dr-light;
  border-radius: $dr-border-radius-small;
  box-shadow: $dr-box-shadow-lighter;
  align-items: center;
  width: 100%;
  max-width: 400px;

  margin: 8px;
  display: flex;

  span.title {
    font-family: $dr-font-family-primary;
    font-size: $dr-font-size-body;
    line-height: $dr-default-line-height;
    color: $dr-black;
    word-break: break-word;
  }

  span.body {
    font-family: $dr-font-family-primary;
    font-size: $dr-font-size-body;
    line-height: $dr-default-line-height;
    color: $dr-charcoal-grey-light;
  }

  .icon-container {
    border-radius: $dr-border-radius-small;
    color: $dr-light;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 8px;
    background-color: var(--theme-color-primary, var(--volta-theme-primary-color-500));

    &.color-error {
      background-color: $dr-error;
    }

    &.color-warning {
      background-color: $dr-warning;
    }
  }
  .text-container {
    display: flex;
    flex-direction: column;
    padding: 8px;
    flex: 1;
  }
  .action-container {
    display: flex;
    flex-direction: column;
    border-left: solid 1px $dr-charcoal-grey-lightest;
    align-self: stretch;

    button {
      flex: 1;
      height: 100%;
      background-color: rgba($dr-black, 0);
      transition: 150ms background-color;
      font-family: $dr-font-family-primary;
      color: $dr-charcoal-grey-light;
      border: none;
      cursor: pointer;
      padding: 0px 8px;
      min-width: 80px;

      &:hover {
        background-color: $dr-charcoal-grey-lightest;
      }

      &.action {
        color: var(--theme-color-primary, var(--volta-theme-primary-color-500));
      }
    }

    button:nth-child(2) {
      border-top: solid 1px $dr-charcoal-grey-lightest;
      border-bottom-right-radius: $dr-border-radius-small;
    }
    button:nth-child(1) {
      border-top-right-radius: $dr-border-radius-small;
    }
  }
}
</style>
