<template>
  <transition-group
    name="toast-top-right"
    tag="div"
    class="toast-manager"
    :style="`top: ${headerMargin}px`">
    <DrToast
      v-for="toast in toasts"
      v-bind="toast.props"
      :key="toast.id"
      @quit="remove(toast.id)"
      @action="action(toast.id)" />
  </transition-group>
</template>

<script>
import DrToast from "./toast.vue"

export default {
  components: {
    DrToast
  },
  props: {
    maxToasts: { type: Number, default: 5 },
    headerMargin: { type: Number, default: 80 }
  },
  data() {
    return {
      toasts: []
    }
  },
  mounted() {
    this.$events.$on("toast", this.onToastEvent)
  },
  methods: {
    onToastEvent(event) {
      this.toast(
        event.title,
        event.text,
        event.icon || "",
        event.deletable || false,
        event.action || "",
        event.callback,
        event.iconColor
      )
    },
    toast(title, text, icon, deletable, action, callback, iconColor, duration = 10) {
      const toastObject = {
        id: (Math.random() * 1e32).toString(16).slice(0, 16),
        props: { title, text, icon, action, deletable, iconColor },
        callback: callback
      }
      this.toasts.unshift(toastObject)
      if (this.toasts.length > this.maxToasts) {
        this.toasts = this.toasts.slice(0, this.maxToasts)
      }
      setTimeout(() => {
        this.remove(toastObject.id)
      }, duration * 1000)
    },
    remove(toastId) {
      this.toasts = this.toasts.filter((t) => t.id != toastId)
    },
    action(toastId) {
      const toast = this.toasts.find((t) => t.id == toastId)
      toast.callback()
      this.remove(toastId)
    }
  }
}
</script>

<style scoped lang="scss">
.toast-manager {
  pointer-events: none;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 80px;
  right: 0px;
  width: calc(100% - 16px);
  z-index: $dr-z-index-first-plan;
  align-items: flex-end;
}

.toast-top-right-enter-active,
.toast-top-right-leave-active {
  transition: all $transition-time;
  transform: translateX(0px);
}
.toast-top-right-enter-active {
  transition-delay: $transition-time !important;
}
.toast-top-right-enter {
  transform: translateX(100px);
  opacity: 0;
}

.toast-top-right-leave-to {
  opacity: 0;
}

.toast-top-right-move {
  transition: transform $transition-time;
}
</style>
